import { customFunctions } from "@/config/customFunction";
import { DefectTypeItem } from "@/features/ui/DefectTypeSet";
import { WorkEquipmentCell } from "@/features/workByManagement/WorkEquipmentCell";
import { WorkNumberInputCell } from "@/features/workByManagement/WorkNumberInputCell";
import { WorkProgressCell } from "@/features/workByManagement/WorkProgressCell";
import { WorkQuantityCell } from "@/features/workByManagement/WorkQuantityCell";
import { useWorkState } from "@/features/workByManagement/hooks/useWorkState";
import { usePub, useSub } from "@/hooks";
import { theme } from "@/styles/theme";
import styled from "@emotion/styled";
import { Checkbox, Flex, Text } from "@mantine/core";
import { AuthSignupPost201ResponseEquipmentWorksInner, ProductionPlansGet200ResponseRowsInnerWorksInner } from "@sizlcorp/sizl-api-document/dist/models";
import dayjs from "dayjs";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useWorkActions } from "./hooks/useWorkActions";

type PageKeyType = 'equipment' | 'plan';

type ItemType<T extends PageKeyType> = T extends 'equipment'
    ? AuthSignupPost201ResponseEquipmentWorksInner : ProductionPlansGet200ResponseRowsInnerWorksInner;

interface Params<T extends PageKeyType> {
    rowData: ItemType<T>;
    pageKey: PageKeyType;
    socketCounter?: string | null;
    defectTypes: DefectTypeItem[] | null;
    bgColor?: string;
    formReset: boolean;
}

// 작업지시 Row의 작업 상태별 색상 정의
const workStatusColors = {
    WAITING: "",
    WORKING: theme.colors?.green?.[0],
    PAUSED: theme.colors?.orange?.[0],
    DONE: theme.colors?.gray?.[4]
};

// 작업지시 Row의 작업 상태 기본 색상 (작업지시가 없을 때)
const defaultWorkStatusColor = theme.colors?.gray?.[4];

export const WorkTableRow = <T extends PageKeyType>({ rowData, pageKey, defectTypes, bgColor, socketCounter, formReset }: Params<T>) => {
    const isPlan = pageKey === 'plan';

    const state = useWorkState();
    const actions = useWorkActions();
    const publish = usePub();
    const checkRef = useRef<HTMLInputElement>(null);
    const [checkedValues, setCheckedValues] = useState(false);
    const trackingStatus = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).trackingStatus;
    const lastWorkDate = (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate
        ? dayjs((rowData as ProductionPlansGet200ResponseRowsInnerWorksInner).lastWorkDate).format('YYYY-MM-DD')
        : '';

    const [localSocketCounter, setLocalSocketCounter] = useState(socketCounter);

    const checkWorkSync = (workSync: string | null) => {
        if (!workSync) return null;

        const workSyncArr = workSync.split(",");
        return workSyncArr.length > 1 ? "사외 외주 실적" : "실적";
    };


    const handleQuantityChange = ({ key, value }: { key: string; value: string }) => {
        const data = state.tempQuantity.find((quantity) => quantity.key === (rowData.id && rowData?.id.toString()));
        if (data) {
            data.value = value;
        } else {
            state.tempQuantity.push({ key, value });
        }
        actions.setTempQuantity(state.tempQuantity);
    };

    const handleDefectChange = ({ key, value }: { key: string; value: string }) => {
        const workData = state.tempDefects.find((defect) => defect.id === (rowData.id && rowData?.id.toString()));
        const data = workData?.defect.find((defect) => defect.key === key);
        if (data) {
            data.value = value;
        } else {
            workData
                ? workData.defect.push({ key, value })
                : state.tempDefects.push({ id: (rowData.id ? rowData.id.toString() : undefined), defect: [{ key, value }] });
        }
        actions.setTempDefects(state.tempDefects);
    };

    const columns = useMemo(() => [
        {
            dataComponent: (
                <Text ta="right">{rowData.scheduleSeq}</Text>
            ),
            index: 1,
            minWidth: 5,
            width: 8,
            condition: isPlan
        },
        {
            dataComponent: (
                <Text>{lastWorkDate}</Text>
            ),
            index: 2,
            minWidth: 12,
            width: 20,
            condition: isPlan
        },
        {
            dataComponent: (
                <Text>{rowData?.id}</Text>
            ),
            index: 3,
            minWidth: 6,
            width: 10,
            condition: isPlan && customFunctions.ADD_WORK_ID_COLUMN
        },
        {
            dataComponent: (
                <Text>{checkWorkSync((rowData as AuthSignupPost201ResponseEquipmentWorksInner & { description: string | null })?.description)}</Text>
            ),
            index: 4,
            minWidth: 3,
            width: 5,
            condition: isPlan && customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN
        },
        {
            dataComponent: (
                <WorkEquipmentCell data={{ equipmentCode: rowData.equipmentCode, equipmentName: rowData.equipmentName }} />
            ),
            index: 5,
            minWidth: 12,
            width: 20,
            condition: true
        },
        {
            dataComponent: (
                <Flex direction="column">
                    <Text>{rowData?.erpWorkOrderSerl}</Text>
                    <Text>{rowData?.description}</Text>
                </Flex>
            ),
            index: 6,
            minWidth: 6,
            width: 10,
            condition: isPlan && customFunctions.ADD_DESCRIPTION_COLUMN
        },
        {
            dataComponent: (
                <WorkProgressCell
                    data={{
                        itemName: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.name as string,
                        itemCode: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.code as string,
                        itemId: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.id as number,
                        itemSpec: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.spec as string,
                        workId: rowData.id,
                        routingCode: rowData.routingCode,
                        percent: rowData.summary?.percent ?? "0",
                        trackingStatus: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.trackingStatus,
                        purchaseOrderItem: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.purchaseOrderItem,
                    }}
                />
            ),
            index: 7,
            minWidth: 22,
            width: 31,
            condition: true
        },
        {
            dataComponent: (
                <WorkQuantityCell
                    data={{
                        targetQuantity: rowData.targetQuantity,
                        totalQuantity: rowData.summary?.totalQuantity,
                        todoQuantity: rowData.summary?.todoQuantity,
                        defectTotal: rowData.summary?.defectTotal,
                        unitText: (rowData as ProductionPlansGet200ResponseRowsInnerWorksInner)?.item?.unitText as string,
                    }}
                />
            ),
            index: 8,
            minWidth: 18,
            width: 31,
            condition: true
        },
        {
            dataComponent: (
                rowData.id ? <WorkNumberInputCell
                    data={{
                        key: (rowData.id as number).toString(),
                        defaultValue: rowData?.summary?.end ?? "0",
                        value: localSocketCounter !== undefined && localSocketCounter !== null ? localSocketCounter : (
                            state.tempQuantity.find(data => data.key === rowData.id?.toString())?.value ??
                            rowData?.summary?.end ??
                            "0"
                        ),
                        onChange: handleQuantityChange,
                        formReset: formReset ?? false,
                    }}
                /> : null
            ),
            index: 9,
            minWidth: 8,
            width: 14,
            condition: true
        },
    ].filter(col => col.condition).map((col, index) => ({ ...col, index: index + 1 })),
        [isPlan, customFunctions.ADD_WORK_ID_COLUMN, customFunctions.ADD_EXTERNAL_OUTSOURCING_COLUMN, customFunctions.ADD_DESCRIPTION_COLUMN, rowData, localSocketCounter, state.tempQuantity, state.tempDefects, formReset]
    );

    const refs = useRef<(HTMLTableHeaderCellElement | null)[]>([]);
    const [widths, setWidths] = useState<number[]>(new Array(columns.length).fill(0));

    useEffect(() => {
        setLocalSocketCounter(socketCounter);
        handleQuantityChange({
            key: rowData.id?.toString() ?? "",
            value: socketCounter ?? rowData?.summary?.end ?? "0"
        });
    }, [socketCounter]);

    useEffect(() => {
        const newWidths = refs.current.map((ref) => (ref ? ref.offsetWidth : 0));
        setWidths(newWidths);
    }, [state.date, state.works, state.tempWorks]);



    useSub('checkAll', () => {
        setCheckedValues(true);
    });

    useSub('unCheckAll', () => {
        setCheckedValues(false);
    });

    useEffect(() => {
        if (formReset) {
            setCheckedValues(false);
        }
    }, [formReset]);

    const calculateLeftWidth = useCallback((index: number) => {
        return refs.current.slice(0, index).reduce((acc, ref) => acc + (ref ? ref.offsetWidth : 0), 0);
    }, []);

    return (
        <WorkTr color={rowData.id && trackingStatus ? workStatusColors[trackingStatus] : defaultWorkStatusColor}>
            <Td leftWidth={0} minWidth={3} width={4} ref={(el) => (refs.current[0] = el)}>
                <Flex align="center" justify="center">
                    <Checkbox
                        ref={checkRef}
                        checked={checkedValues}
                        onChange={(e) => {
                            if (e.target.checked) {
                                publish('check');
                                setCheckedValues(true);
                                actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => [...prevWorks, rowData]);
                            } else {
                                publish('unCheck');
                                setCheckedValues(false);
                                actions.setWorks((prevWorks: (AuthSignupPost201ResponseEquipmentWorksInner | ProductionPlansGet200ResponseRowsInnerWorksInner)[]) => prevWorks.filter(work => work?.id !== rowData?.id));
                            }
                        }}
                    />
                </Flex>
            </Td>

            {
                columns.map((column) => {
                    return (
                        <Td
                            key={column.index}
                            minWidth={column.minWidth}
                            width={column.width}
                            leftWidth={calculateLeftWidth(column.index)}
                            ref={(el) => (refs.current[column.index] = el)}
                        >
                            {column.dataComponent}
                        </Td>
                    )
                })
            }

            {defectTypes?.map((defectType: DefectTypeItem) => (
                <Td key={defectType.value} minWidth={8} width={14} ref={(el) => (refs.current[10 + defectTypes.indexOf(defectType)] = el)}>
                    {rowData?.id ? (
                        <WorkNumberInputCell
                            data={{
                                key: defectType.value,
                                defaultValue: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "0",
                                value: (rowData?.summary?.defect as any)?.[defectType.value]?.defectQuantity ?? "",
                                onChange: handleDefectChange,
                                formReset: formReset ?? false,
                            }}
                        />
                    ) : null}
                </Td>
            ))}
        </WorkTr>
    );
};

export const Td =
    styled.td<{ minWidth?: number; width?: number; isNumber?: boolean; leftWidth?: number }>`
        min-width: ${(props) => (props.minWidth ? `${props.minWidth}rem` : `auto`)};
        width: ${(props) => (props.width ? `${props.width}%` : `auto`)};
        position: ${(props) => (props.leftWidth !== undefined ? 'sticky' : '')};
        border-right: 0.0625rem solid rgb(222, 226, 230);
        border-bottom: 0.0625rem solid rgb(222, 226, 230);
        left: ${(props) => (props.leftWidth !== undefined ? `${props.leftWidth}px` : 'auto')};
        z-index: ${(props) => (props.leftWidth !== undefined ? 10 : 0)};
        background-color: ${(props) => (props.leftWidth !== undefined ? 'white' : 'transparent')};
    `

const WorkTr = styled.tr`
    td {
       background-color: ${(props) => props.color};
    }
`;

export const TextEllipsis = styled<any>(Text)`
    display: -webkit-box;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
`;